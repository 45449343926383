import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import Form from '../../components/Form';

const Banner = (): JSX.Element => {
  const { t } = useTranslation();
  const [modal, setModal] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formUrl
        }
      }
    }
  `);
  const { formUrl } = data.site.siteMetadata;

  const openModal = () => setModal(true);

  return (
    <Box
      textAlign={{ lg: 'center' }}
      background="gray.900"
      px={{ base: 4, lg: 20 }}
      pt={16}
      pb={12}
    >
      <Container>
        <Box px={{ lg: 12 }}>
          <Heading size={{ base: 'xs', lg: 'sm' }} color={{ base: 'white', lg: 'white' }} mb={2}>
            {t(
              'banner-title',
              'Pectoken permite hacer más y mejores negocios con agrocommodities, creando un ecosistema más simple, eficiente y tecnológico. '
            )}
          </Heading>
          <Text size="lg" color="white" mb={12}>
            {t('banner-subtitle', 'Somos la nueva dimensión de los negocios agropecuarios.')}
          </Text>
          <Button variant="primary" h={12} onClick={openModal}>
            {t('button', 'Quiero saber más')}
          </Button>
          <Form formUrl={formUrl} isOpen={modal} onClose={() => setModal(false)} />
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
