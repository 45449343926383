import React, { useEffect } from 'react';
import { ChakraProvider, Flex, Image } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Script } from 'gatsby';

import selectIntlImage from '../utils/selectIntlImage';

import Header from '../components/Header';
import theme from '../utils/theme';

import pectokenWeb from '../images/pectoken-web.png';
import pectokenWebPT from '../images/pectoken-web-pt.png';

import Landing from '../Sections/Landing';
import Features from '../Sections/Features/Features';
import Pectokens from '../Sections/Pectokens/Pectokens';
import UsePectokens from '../Sections/UsePectokens';
import WhyPectokens from '../Sections/WhyPectokens';
import Container from '../components/Container';
import Agrocommodities from '../Sections/Agrocommodities';
import Join from '../Sections/Join/Join';
import Banner from '../Sections/Banner';
import Footer from '../components/Footer';

export const Head = () => {
  return (
    <>
      <title>Pectoken: Tu hacienda en tu bolsillo.</title>
      <meta
        name="description"
        content="Estamos construyendo un nuevo ecosistema que permite hacer negocios con Agrocommodities, de forma simple, segura y con menores costos."
      />
    </>
  );
};

type Props = {
  data: {
    site: {
      siteMetadata: {
        priceUrl: string;
      };
    };
  };
};

const IndexPage = ({ data }: Props) => {
  const { language } = useI18next();
  const [price, setPrice] = React.useState('');
  const { priceUrl } = data.site.siteMetadata;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]', {
        updateURL: false,
        offset: 50,
      });
    }
    fetch(priceUrl).then((res) => {
      res.json().then((dat) => {
        const { referenceCurrency, price: pecPrice } = dat;
        setPrice(`${referenceCurrency} ${parseFloat(pecPrice).toFixed(2)}`);
      });
    });
  }, []);

  return (
    <>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-899CRC70FD" />
      <Script
        id="gtag-script"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-899CRC70FD');`,
        }}
      />
      <ChakraProvider theme={theme}>
        <Flex direction="column">
          <Header price={price} />
          <Landing price={price} />
          <Features />
          <Pectokens />
          <UsePectokens />
          <WhyPectokens />
          <Container>
            <Image
              src={selectIntlImage(language, pectokenWeb, pectokenWebPT)}
              alt="pec-logo"
              px={{ base: 4, lg: 44 }}
            />
          </Container>
          <Agrocommodities />
          <Join />
          <Banner />
        </Flex>
        <Footer />
      </ChakraProvider>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        priceUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default IndexPage;
