import { Box, Button, Flex, Heading, Image, Show, Text, Badge, Hide } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';

import selectIntlImage from '../../utils/selectIntlImage';

// images
import { graphql, useStaticQuery } from 'gatsby';
import landing from '../../images/landing.png';
import landingPT from '../../images/landing-pt.png';
import icon1 from '../../images/icon-1.svg';
import Container from '../../components/Container';
import Form from '../../components/Form';

const Landing = ({ price }: { price: string }): JSX.Element => {
  const { t } = useTranslation('landing');
  const { language } = useI18next();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formUrl
        }
      }
    }
  `);
  const { formUrl } = data.site.siteMetadata;
  return (
    <Box mx={{ base: 4, lg: 20 }} mt={16} mb={28}>
      <Container>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify="space-between"
          alignItems="flex-start"
        >
          <Box w={{ lg: '45%' }}>
            <Badge variant="orange" mb={4}>
              {t('badge', 'Próximamente')}
            </Badge>
            <Hide above="lg">
              <Heading as="h1" size="md" mb={1.5}>
                {t('heading-mobile', 'Tu hacienda en tu bolsillo')}
              </Heading>
            </Hide>
            <Show above="lg">
              <Heading as="h1" size="2xl" mb={1.5}>
                {t('heading', 'Tu hacienda en \ntu bolsillo')
                  .split('\n')
                  .map((item) => (
                    <Text key={item}>{item}</Text>
                  ))}
              </Heading>
            </Show>
            <Text size="xl" mb={8} fontWeight="400">
              {t(
                'description',
                'Tokenizá tu hacienda y disponé de su valor cuando lo necesites. Ahora podes entregar tu hacienda en el momento óptimo, manteniendo tu posición en kilos de novillo con precio a fijar'
              )}
            </Text>
            <Button variant="primary" h={{ base: 12, lg: '60px' }} onClick={toggleModal}>
              {t('button', 'Quiero saber más')}
            </Button>
            <Form isOpen={isOpen} onClose={toggleModal} formUrl={formUrl} />
            <Box py={12}>
              <Flex align="center">
                <Text size="md">{t('price', 'Cotización de nuestro token:')}</Text>
                <Image src={icon1} alt="token" w="22px" mx={2} pos="relative" top="-2px" />
                <Text size="sm">BovA: {price}</Text>
              </Flex>
            </Box>
          </Box>
          <Image position="relative" w="550px" src={selectIntlImage(language, landing, landingPT)} alt="pectoken" />
        </Flex>
      </Container>
    </Box>
  );
};

export default Landing;
