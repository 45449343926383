import React from 'react';

import { Badge, Box, Grid, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../../components/Container';
import Feature from '../../components/Feature';

import feature7 from '../../images/features/feature-7.svg';
import feature8 from '../../images/features/feature-8.svg';
import feature9 from '../../images/features/feature-9.svg';
import feature10 from '../../images/features/feature-10.svg';
import Form from '../../components/Form';

const Join = (): JSX.Element => {
  const { t } = useTranslation('join');
  const [open, setOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formUrl
        }
      }
    }
  `);
  const { formUrl } = data.site.siteMetadata;

  const actors = [
    {
      title: t('actors.0.title', 'Productores'),
      subtitle: t(
        'actors.0.subtitle',
        'Resguardá el valor de tu negocio convirtiendo tu hacienda en Pectokens para intercambiarlos cuando necesites.'
      ),
      icon: feature7,
    },
    {
      title: t('actors.1.title', 'Comercios'),
      subtitle: t(
        'actors.1.subtitle',
        'Aceptá Pectokens a cambio de tus productos o servicios y aumentá tus ventas.'
      ),
      icon: feature8,
    },
    {
      title: t('actors.2.title', 'Consignatarios'),
      subtitle: t(
        'actors.2.subtitle',
        'Sumate al ecosistema e incorporá un nuevo medio de pago para ofrecer a tus clientes.'
      ),
      icon: feature9,
    },
    {
      title: t('actors.3.title', 'Inversores'),
      subtitle: t(
        'actors.3.subtitle',
        'Si ya operás con criptoactivos o te interesan las oportunidades del mundo de los negocios agropecuarios.'
      ),
      icon: feature10,
    },
  ];

  return (
    <Box id="be-part" px={{ base: 4, lg: 20 }} pt={12} pb={24}>
      <Container>
        <Box w={{ lg: '768px' }}>
          <Badge variant="orange" mb={3}>
            {t('tag', 'Nuevas posibilidades')}
          </Badge>
          <Heading size={{ base: 'sm', lg: 'md' }} mb={5}>
            {t('join.title', '¿Querés formar parte del ecosistema?')}
          </Heading>
          <Text size={{ base: 'lg', lg: 'xl' }} mb={{ base: 8, lg: 16 }}>
            {t(
              'join.subtitle',
              'Unimos la tecnología, las finanzas, la criptoeconomía y los negocios agropecuarios, para que todos puedan aprovechar las oportunidades de un mundo más simple y eficiente.'
            )}
          </Text>
        </Box>
        <Grid templateColumns={{ lg: 'repeat(4, 1fr)' }} textAlign="left" gap={6}>
          {actors.map((actor, i) => (
            <Feature
              bordered
              key={actor.title}
              title={actor.title}
              subtitle={actor.subtitle}
              icon={actor.icon}
              onClick={{
                callback: () => setOpen(true),
                text: t('register', 'Registrate'),
              }}
              badge={i === 3 ? t('badge', 'Próximamente') : undefined}
            />
          ))}
        </Grid>
        <Form isOpen={open} onClose={() => setOpen(false)} formUrl={formUrl} />
      </Container>
    </Box>
  );
};

export default Join;
