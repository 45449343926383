import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type FormProps = {
  formUrl: string;
  isOpen: boolean;
  onClose: () => void;
};

const Form = ({ formUrl, isOpen, onClose }: FormProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const object: Record<string, any> = {};
    data.forEach((value, key) => {
      object[key] = value;
    });
    const body = JSON.stringify(object);

    fetch(formUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body,
    }).then(() => {
      toast({
        title: 'Muchas gracias por contactarnos.',
        description: 'En breve nos comunicaremos contigo.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="665px">
        <form onSubmit={handleSubmit}>
          <ModalHeader>{t('form.title', 'Formulario de contacto')}</ModalHeader>
          <ModalBody>
            <Text mb={8}>
              {t(
                'form.subtitle',
                'Dejanos tus datos para que podamos ayudarte en lo que necesites, o contarte más acerca de esta nueva dimensión de los negocios agropecuarios:'
              )}
            </Text>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={6}>
              <GridItem w="100%">
                <FormControl mb={4}>
                  <FormLabel>{t('form.name', 'Nombre y Apellido')}</FormLabel>
                  <Input required name="nombre" placeholder="Ingresá tu nombre y apellido" />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>{t('form.email', 'Correo electrónico')}</FormLabel>
                  <Input
                    required
                    name="email"
                    type="email"
                    placeholder="Ingresá tu correo electrónico"
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>{t('form.company', 'Empresa')}</FormLabel>
                  <Input required name="company" placeholder="Ingresá tu empresa" />
                </FormControl>
              </GridItem>
              <GridItem w="100%">
                <FormControl mb={4}>
                  <FormLabel>{t('form.phone', 'Teléfono')}</FormLabel>
                  <Input required name="phone" type="tel" placeholder="Ingresá tu teléfono" />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>{t('form.role', '¿Cuál es tu función?')}</FormLabel>
                  <Select name="role">
                    <option value="" selected disabled>
                      Seleccioná cuál es tu función
                    </option>
                    <option value="productor">Productor Ganadero</option>
                    <option value="consignatario">Consignatario</option>
                    <option value="frigorifico">Frigorifico</option>
                    <option value="matarife">Matarife</option>
                    <option value="comercio">Comercio</option>
                    <option value="Inversor">Inversor</option>
                    <option value="otro">Otro</option>
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
            <FormControl mb={4}>
              <FormLabel>{t('form.message', 'Mensaje')}</FormLabel>
              <Textarea name="message" placeholder="Escribí tu mensaje" />
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button variant="primary" mr={3} type="submit">
              {t('submit', 'Enviar')}
            </Button>
            <Button variant="outline" onClick={onClose} fontWeight="400">
              {t('cancel', 'Tal vez luego')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default Form;
