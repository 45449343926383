import React from 'react';

import { Badge, Box, Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Feature from '../../components/Feature';

import why1 from '../../images/icon-2.svg';
import why2 from '../../images/features/feature-5.svg';
import why3 from '../../images/features/feature-4.svg';
import Container from '../../components/Container';

const WhyPectokens = (): JSX.Element => {
  const { t } = useTranslation('why');

  const benefits = [
    {
      title: t('benefits.0.title', 'Tu moneda son tus kilos de Novillo'),
      subtitle: t(
        'benefits.0.subtitle',
        'Los pectokens te permiten preservar el valor de tu hacienda sin tener que venderlos hasta el momento que quieras utilizarlos.'
      ),
      icon: why1,
    },
    {
      title: t('benefits.1.title', 'Protege el valor de tu negocio'),
      subtitle: t(
        'benefits.1.subtitle',
        'Su valor está ligado al precio de la hacienda. 1 Pectoken = 1 Kg vivo de Novillo valuado según el INMAG (Índice de Novillo del Mercado Agroganadero de Cañuelas).'
      ),
      icon: why2,
    },
    {
      title: t('benefits.2.title', 'Utiliza toda la infraestructura de Agrotoken'),
      subtitle: t(
        'benefits.2.subtitle',
        'Es la única infraestructura global dedicada a la tokenización de agrocommodities.  Un ecosistema seguro y sin fricciones para que transaccionar con commodities del agro sea fácil, eficiente y confiable'
      ),
      icon: why3,
    },
  ];

  return (
    <Box
      id="why"
      textAlign={{ lg: 'center' }}
      px={{ base: 4, lg: 20 }}
      pt={{ base: 16, lg: 24 }}
      pb="52px"
    >
      <Container>
        <Badge variant="orange" mb={4}>
          {t('benefits', 'Beneficios')}
        </Badge>
        <Heading size={{ base: 'xs', lg: 'md' }} mb="38px">
          {t('why-title', '¿Por qué tokenizar mi hacienda?')}
        </Heading>
        <Flex direction={{ base: 'column', lg: 'row' }} gap={{ base: 6, lg: 0 }}>
          {benefits.map((benefit) => (
            <Feature
              key={benefit.title}
              icon={benefit.icon}
              title={benefit.title}
              subtitle={benefit.subtitle}
              centered
            />
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default WhyPectokens;
