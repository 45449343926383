export type Item = {
  title: string;
  links: Array<{
    id: string;
    url: string;
  }>;
};

const items: Item[] = [
  {
    title: 'menu.title',
    links: [
      {
        id: 'menu.home',
        url: '#main',
      },
      {
        id: 'menu.wedo',
        url: '#we-do',
      },
      {
        id: 'menu.usecases',
        url: '#use-cases',
      },
      {
        id: 'menu.bepart',
        url: '#be-part',
      },
    ],
  },
  {
    title: 'menu.privacy-title',
    links: [
      {
        id: 'menu.cookies',
        url: 'https://agrotoken.notion.site/Uso-de-Cookies-y-tecnolog-as-similares-23ee818396124ab0876d5166751fd95a',
      },
      {
        id: 'menu.privacy',
        url: 'https://agrotoken.notion.site/Pol-tica-de-Privacidad-d68b3dc1cfdd4752855eb3485addc15f',
      },
      {
        id: 'menu.terms',
        url: 'https://agrotoken.notion.site/T-rminos-y-Condiciones-Generales-7d368b4511c94456bad63cf7b69b21b5',
      },
    ],
  },
  {
    title: 'social.title',
    links: [
      {
        id: 'social.twitter',
        url: 'https://twitter.com/agrotoken',
      },
      {
        id: 'social.instagram',
        url: 'https://www.instagram.com/agrotoken/',
      },
      {
        id: 'social.linkedIn',
        url: 'https://www.linkedin.com/company/agrotoken',
      },
    ],
  },
];

export default items;
