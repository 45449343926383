import React from 'react';

import { Box } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};

const Container = ({ children }: Props): JSX.Element => {
  return (
    <Box maxW="7xl" mx="auto">
      {children}
    </Box>
  );
};

export default Container;
