import React from 'react';

import { Box, Flex, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';

import selectIntlImage from '../../utils/selectIntlImage';

import Container from '../../components/Container';

import usePectokens from '../../images/use-pectokens.png';
import usePectokensPT from '../../images/use-pectokens-pt.png';
import AttributesList from '../../components/AttributesList';

import icon2 from '../../images/icon-2.svg';

const UsePectokens = (): JSX.Element => {
  const { t } = useTranslation('usePectokens');
  const { language } = useI18next();

  const attributes = [
    t(
      'attributes.0',
      'Mejorar la rentabilidad de tu negocio fijando el precio de tu hacienda cuando lo necesites.'
    ),
    t(
      'attributes.1',
      'Realizar compras de productos y servicios en la red de comercios adheridos.'
    ),
    t('attributes.2', 'Pagar con una tarjeta Visa internacional, prefondeada con tus tokens.'),
    t('attributes.3', 'Pedir préstamos en grandes entidades financieras.'),
    t('attributes.4', 'Intercambiarlos por Agrotokens de Maiz, Trigo o Soja.'),
    t('attributes.5', 'Convertir tus Pectokens en pesos cuando quieras.'),
  ];

  return (
    <Box id="use-cases" px={{ base: 4, lg: 20 }} pb={{ lg: '84px' }}>
      <Container>
        <Flex direction={{ base: 'column', lg: 'row' }} justify="space-between" align="flex-start">
          <Box mt={{ base: 16, lg: 20 }}>
            <AttributesList
              icon={icon2}
              title={t('use-title', 'Transformar tu hacienda en Pectokens te permite:')}
              attributes={attributes}
            />
          </Box>
          <Image position="relative" w="530px" src={selectIntlImage(language, usePectokens, usePectokensPT)} alt="usePectokens" />
        </Flex>
      </Container>
    </Box>
  );
};

export default UsePectokens;
