// i18next-extract-disable
import React from 'react';
import { Link, Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Item } from '../Footer/data';

const FooterItem = ({ title, links }: Item): JSX.Element => {
  const { t } = useTranslation('footer');
  return (
    <Box w="100%" mb={6}>
      <Text size="sm" color="gray.400" fontWeight="semibold" mb={4}>
        {t(title)}
      </Text>
      <UnorderedList spacing={3} style={{ listStyleType: 'none' }} m={0}>
        {links.map((link) => (
          <ListItem key={link.id}>
            <Link href={link.url} className="hover:text-gray-400">
              <Text size="md" color="white">
                {t(link.id)}
              </Text>
            </Link>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default FooterItem;
