import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  breakpoints: {
    lg: '1280px',
  },
  fonts: {
    body: `'Larsseit', sans-serif`,
    heading: `'Larsseit', sans-serif`,
  },
  components: {
    Text: {
      sizes: {
        sm: {
          fontSize: '14px',
          lineHeight: '20px',
        },
        md: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#344054',
        },
        lg: {
          fontWeight: '400',
          fontSize: '18px',
          lineHeight: '28px',
        },
        xl: {
          fontWeight: '400',
          fontSize: '20px',
          lineHeight: '30px',
        },
      },
    },
    Heading: {
      sizes: {
        xs: {
          fontWeight: '500',
          fontSize: '24px',
          lineHeight: '32px',
        },
        sm: {
          fontWeight: '500',
          fontSize: '30px',
          lineHeight: '38px',
          letterSpacing: '-0.02em',
          color: '#101828',
        },
        md: {
          fontWeight: '500',
          fontSize: '36px',
          lineHeight: '44px',
          letterSpacing: '-0.02em',
          color: '#101828',
        },
        lg: {
          fontWeight: '500',
          fontSize: '48px',
          lineHeight: '60px',
          letterSpacing: '-0.02em',
          color: '#101828',
        },
        '2xl': {
          fontWeight: '500',
          fontSize: '72px',
          lineHeight: '90px',
          letterSpacing: '-0.02em',
        },
      },
    },
    Button: {
      variants: {
        primary: {
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '28px',
          bg: '#FB6514;',
          color: '#fff',
          padding: '16px 28px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          borderRadius: '3px',
          _hover: {
            bg: '#C4320A',
          },
        },
      },
    },
    Badge: {
      variants: {
        orange: {
          fontSize: '14px',
          lineHeight: '20px',
          background: '#FFF6ED',
          borderRadius: '16px',
          padding: '4px 12px',
          color: '#C4320A',
          textTransform: 'capitalize',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#344054',
      },
    },
  },
  colors: {
    'bg.gradient': 'linear-gradient(180deg, #F9FAFB 0%, rgba(249, 250, 251, 0) 100%);',
    gray: {
      400: '#98A2B3',
      500: '#667085',
      700: '#344054',
      900: '#101828',
    },
    borders: {
      200: '#E4E7EC',
      500: '#F2F4F7;',
    },
  },
});

export default theme;
