import React from 'react';
import { Box, Flex, Heading, Text, Grid, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Feature from '../../components/Feature/Feature';
import Container from '../../components/Container';

// images
import icon1 from '../../images/icon-1.svg';
import feature1 from '../../images/features/feature-1.svg';
import feature2 from '../../images/features/feature-2.svg';
import feature3 from '../../images/features/feature-3.svg';
import feature4 from '../../images/features/feature-4.svg';
import partners1 from '../../images/partners-1.png';
// import partners2 from '../../images/partners-2.png';
import partners3 from '../../images/partners-3.png';

const Features = (): JSX.Element => {
  const { t } = useTranslation('features');
  return (
    <Box background="bg.gradient" px={{ base: 4, lg: 20 }} mb={{ base: 16, lg: 24 }}>
      <Container>
        <Flex textAlign={{ lg: 'center' }} direction="column" w="100%" my={{ base: 16, lg: 24 }}>
          <Text size="md" mb={{ base: 4, lg: 8 }}>
            {t('partners', 'Nos acompañan:')}
          </Text>
          <Flex h={{ lg: '35px' }} align="center" justify={{ lg: 'center' }} wrap="wrap" gap={8}>
            <Image w="144px" src={partners1} alt="agrotoken" />
            {/* <Image w="86px" mx="28px" src={partners2} alt="minerva" /> */}
            <Image w="110px" src={partners3} alt="mercado agroganadero" />
          </Flex>
        </Flex>
        <Flex
          direction="column"
          align={{ base: 'flex-start', lg: 'center' }}
          textAlign={{ lg: 'center' }}
          id="we-do"
        >
          <Image src={icon1} alt="features" mb={5} />
          <Heading w={{ lg: '75%' }} as="h2" size={{ base: 'xs', lg: 'md' }} mb={5}>
            {t(
              'title',
              'Transformamos tu hacienda en un activo digital, para guardar o intercambiar por insumos, servicios y otros activos.'
            )}
          </Heading>
          <Text w={{ lg: '85%' }} size={{ base: 'lg', lg: 'xl' }} mb={{ base: 8, lg: 16 }}>
            {t(
              'subtitle',
              'Tokenizar es transformar tu hacienda en un activo digital. Con tus Pectokens podrás realizar transacciones comerciales y financieras de manera fácil y segura.'
            )}
          </Text>

          <Grid templateColumns={{ lg: 'repeat(4, 1fr)' }} textAlign="left" gap={6}>
            <Feature
              bordered
              icon={feature3}
              title={t('real', 'Real')}
              subtitle={t('real-desc', '1 Pectoken está respaldado por 1 Kilo de Novillo.')}
            />
            <Feature
              bordered
              icon={feature2}
              title={t('estable', 'Estable')}
              subtitle={t(
                'estable-desc',
                'Está valuado según el INMAG de Cañuelas (Índice de Novillos del Mercado Agroganadero)'
              )}
            />
            <Feature
              bordered
              icon={feature1}
              title={t('flexible', 'Flexible')}
              subtitle={t(
                'flexible-desc',
                'Transaccioná cuando quieras, donde quieras y por el monto que quieras.'
              )}
            />
            <Feature
              bordered
              icon={feature4}
              title={t('seguro', 'Seguro')}
              subtitle={t(
                'seguro-desc',
                'Con tecnología blockchain, transparente y descentralizada.'
              )}
            />
          </Grid>
        </Flex>
      </Container>
    </Box>
  );
};

export default Features;
