import React from 'react';

import { Box, Flex, Heading, Image, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import check from '../../images/check.svg';

type Props = {
  title: string | JSX.Element;
  subtitle?: string;
  icon: string;
  attributes: Array<string>;
};

const AttributesList = ({ icon, title, subtitle, attributes }: Props): JSX.Element => {
  return (
    <Box mr={{ lg: 9 }} mb={{ base: 8, lg: 24 }}>
      <Image src={icon} alt="icon" mb={5} />
      <Heading size={{ base: 'xs', lg: 'md' }} mb={8}>
        {title}
      </Heading>
      {subtitle && (
        <Text size="md" mb={8}>
          {subtitle}
        </Text>
      )}
      <UnorderedList spacing={5} m={0} mr={{ lg: 9 }}>
        {attributes.map((attribute) => (
          <ListItem as={Flex} key={attribute} alignItems="flex-start">
            <Image src={check} alt="check" mr={3} />
            <Text size="md">{attribute}</Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

AttributesList.defaultProps = {
  subtitle: undefined,
};

export default AttributesList;
