import React from 'react';
import { Box, Flex, Heading, Text, chakra, Image } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import Container from '../../components/Container';

import bova from '../../images/bova.svg';

const Pectokens = () => {
  const { t } = useTranslation('pectokens');

  return (
    <Box px={{ base: 4, lg: 20 }} py={{ lg: 24 }}>
      <Container>
        <Heading as="h2" size={{ base: 'sm', lg: 'lg' }} mb={4} w={{ base: '75%', lg: '' }}>
          <Trans i18nkey="pectokens-1" ns="pectokens">
            Los <chakra.span color="#FB6514">pectokens</chakra.span> son activos digitales
          </Trans>
          <br />
          <Trans i18nkey="pectokens-2" ns="pectokens">
            transaccionables, respaldados con hacienda.
          </Trans>
        </Heading>
        <Text size={{ base: 'lg', lg: 'xl' }} mb={8} w={{ lg: '65%' }} color="gray.700">
          {t(
            'pectoken-desc',
            'Su valor está ligado al precio de la hacienda. El pectoken denominado BovA es igual a 1 kilo vivo de Novillo según el índice pectoken (I.Pec.BovA) publicado en el MAG (Mercado Agroganadero de Cañuelas).'
          )}
        </Text>
        <Box display="inline-block" border="1px" borderColor="borders.200">
          <Flex align="flex-start" p={6}>
            <Image src={bova} alt="pectokens" mr={4} />
            <Box>
              <Text size="xl" fontWeight="semibold">
                {t('boba-title', '1 BovA = 1 Kg de novillo')}
              </Text>
              <Text size="sm" color="gray.700">
                {t('bova-desc', 'Según índice pectoken (I.Pec.BovA)')}
              </Text>
              <Text size="sm" color="gray.500" maxW="592px">
                {t(
                  'bova-desc-2',
                  'El precio del índice I.Pec.BovA es el resultado promedio de los últimos 3 días de operación del INMAG (Índice de Novillo del Mercado Agroganadero) de Cañuelas.'
                )}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default Pectokens;
