/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons';

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  chakra,
  Flex,
  Hide,
  HStack,
  IconButton,
  Image,
  Show,
  Spacer,
  Link,
  Text,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import logo from '../../images/logo.png';
import Form from '../Form';
import icon1 from '../../images/icon-1.svg';

function Header({ price }: { price: string }): JSX.Element {
  const { t } = useTranslation('header');
  const { language, languages, changeLanguage } = useI18next();

  const [isOpen, setIsOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formUrl
        }
      }
    }
  `);
  const { formUrl } = data.site.siteMetadata;
  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen && typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <chakra.header borderBottom="1px" borderColor="borders.200" id="main">
      <Flex px={{ base: 4, lg: 12 }} h="70px" justify="space-between" align="center">
        <Image src={logo} alt="pectoken" w="166px" />
        <Show above="lg">
          <Spacer />
          <HStack fontSize="sm" color="gray.700" spacing="24px">
            <Link href="#we-do">{t('we-do', '¿Qué hacemos?')}</Link>
            <Link href="#use-cases">{t('use-cases', 'Casos de uso')}</Link>
            <Link href="#why">{t('why', '¿Porqué tokenizar mi hacienda?')}</Link>
            {/* <Link href="#">{t('whitepaper', 'Whitepaper')}</Link> */}
          </HStack>
          <Spacer />
          <Flex align="center" mr={8}>
            <Image src={icon1} alt="token" w="22px" mr={1} pos="relative" top="-2px" />
            <Text size="sm">BovA: {price}</Text>
          </Flex>
          <Button variant="outline" fontWeight="normal" fontSize="sm" onClick={toggleModal} marginRight="2">
            {t('get-involved', 'Quiero Saber Más')}
          </Button>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} defaultValue={language} variant='outline'>
              { language }
            </MenuButton>
            <MenuList>
              { languages.map(lang => {
                return <MenuItem onClick={() => changeLanguage(lang)} key={lang}>{ lang }</MenuItem>
              }) }
            </MenuList>
          </Menu>
        </Show>
        <Hide above="lg">
          <IconButton
            aria-label="open menu"
            fontSize="20px"
            variant="unstyled"
            icon={<HamburgerIcon />}
            onClick={toggleMenu}
          />
        </Hide>
      </Flex>
      <Flex
        position="fixed"
        height={isOpen ? '100vh' : '0'}
        overflow="hidden"
        background="white"
        zIndex="9"
        width="100%"
        direction="column"
        transition="height 0.2s ease-in-out"
        gap={6}
        m={4}
      >
        <Link onClick={toggleMenu} href="#we-do">
          {t('we-do', '¿Qué hacemos?')}
        </Link>
        <Link onClick={toggleMenu} href="#use-cases">
          {t('use-cases', 'Casos de uso')}
        </Link>
        <Link onClick={toggleMenu} href="#why">
          {t('why', '¿Por qué tokenizar mi hacienda?')}
        </Link>
        {/* <Link onClick={toggleMenu} href="#">
          {t('whitepaper', 'Whitepaper')}
        </Link> */}
        <Button variant="outline" fontWeight="normal" fontSize="sm" onClick={toggleModal}>
          {t('get-involved', 'Quiero Saber Más')}
        </Button>
        <Form isOpen={modalOpen} onClose={toggleModal} formUrl={formUrl} />
      </Flex>
    </chakra.header>
  );
}
export default Header;
