import React from 'react';

import { Box, Flex, Image } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import AttributesList from '../../components/AttributesList';
import agrocommidities from '../../images/agrocommodities.png';
import share from '../../images/share.svg';
import Container from '../../components/Container';

const Agrocommodities = () => {
  const { t } = useTranslation('agrocommodities');

  const getTitle = () => {
    return (
      <Trans i18nKey="agro-title" ns="agrocommodities">
        Bienvenidos a una <br /> infraestructura democrática y <br /> eficiente.
      </Trans>
    );
  };

  const attributes = [
    t('agro.attributes.0', 'Nuevas stablecoins colaterizadas con agrocomodities reales.'),
    t(
      'agro.attributes.1',
      'Más funcionalidades para el respaldo de transacciones y financiamiento.'
    ),
    t('agro.attributes.2', 'Apertura a exchanges e intercambio por más activos digitales.'),
    t('agro.attributes.3', 'Más transparencia en los precios.'),
  ];

  return (
    <Box px={{ base: 4, lg: 20 }} py={{ base: 16, lg: 32 }}>
      <Container>
        <Flex direction={{ base: 'column-reverse', lg: 'row' }} align="flex-start">
          <Image src={agrocommidities} alt="agrocommodities" w="560px" mr={16} />
          <AttributesList
            icon={share}
            title={getTitle()}
            subtitle={t(
              'agro-subtitle',
              'Estamos construyendo un nuevo ecosistema que permite hacer negocios con Agrocommodities, de forma simple, segura y con menores costos.'
            )}
            attributes={attributes}
          />
        </Flex>
      </Container>
    </Box>
  );
};

export default Agrocommodities;
