import { chakra, Box, Text, Flex, Image, Grid, Link } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../Container';
import logo from '../../images/logo.png';
import FooterItem from '../FooterItem';
import data from './data';

import gh from '../../images/social/gh.svg';
import tw from '../../images/social/tw.svg';
import ig from '../../images/social/ig.svg';
import ln from '../../images/social/ln.svg';

const Footer = (): JSX.Element => {
  const { t } = useTranslation('footer');
  return (
    <chakra.footer background="gray.900" px={{ base: 4, lg: 20 }} pb={12}>
      <Container>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          color="white"
          py={16}
          borderY="1px"
          borderColor="gray.700"
        >
          <Box w="320px" mr={16}>
            <Image w="142px" src={logo} alt="pec-logo" mb={6} />
            <Text mb={12}>
              {t('pectoken-phrase', 'La nueva dimensión de los negocios agropecuarios')}
            </Text>
          </Box>
          <Flex flex={1} direction={{ base: 'column', lg: 'row' }}>
            {data.map((link) => (
              <FooterItem key={link.title} title={link.title} links={link.links} />
            ))}
          </Flex>
        </Flex>
        <Flex justify="space-between" pt={16} color="gray.400">
          <Text fontSize="sm">{t('copy', '© 2022 Pectoken. Todos los derechos reservados.')}</Text>
          <Grid gap={6} templateColumns="repeat(4, 1fr)">
            <Link href="https://github.com/agrotoken" target="_blank">
              <img src={gh} alt="tw" />
            </Link>
            <Link href="https://twitter.com/agrotoken" target="_blank">
              <img src={tw} alt="tw" />
            </Link>

            <Link href="https://www.instagram.com/agrotoken/" target="_blank">
              <img src={ig} alt="ig" />
            </Link>
            <Link href="https://www.linkedin.com/company/agrotoken" target="_blank">
              <img src={ln} alt="li" />
            </Link>
          </Grid>
        </Flex>
      </Container>
    </chakra.footer>
  );
};

export default Footer;
